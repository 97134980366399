import React from 'react'
import {Link} from 'gatsby'
import {Location} from '@reach/router'

export default props => (
  <Location>
    {({location}) => {
      return (
        <footer className="mt-16 mb-32">
          <nav>
            <Link to={props.to ? props.to : '/'} className="text-sm">
              {props.message ? props.message : '← Back'}
            </Link>
          </nav>
        </footer>
      )
    }}
  </Location>
)
