import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer'

const ProjectsPage = ({ data, location }) => {
  return (
    <Layout location={location} title="Projects">
      <SEO
        title="Projects"
        description="Some of the past projects of Mark Southard"
      />
      <h2 className="text-gray-500 text-xs uppercase tracking-widest">
        Past Projects:
      </h2>
      <ol className="list-decimal">
        <li>
          <h3>Dunked Templates:</h3>
          <a
            href="https://blocksdemo.dunked.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blocks
          </a>
          ,{' '}
          <a
            href="https://liftdemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lift
          </a>
          ,{' '}
          <a
            href="https://boardsdemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Boards
          </a>
          ,{' '}
          <a
            href="https://apexdemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apex
          </a>
          ,{' '}
          <a
            href="https://benchdemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bench
          </a>
          ,{' '}
          <a
            href="https://axisdemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Axis
          </a>
          ,{' '}
          <a
            href="https://paragondemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Paragon
          </a>
          ,{' '}
          <a
            href="https://masondemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mason
          </a>
          ,{' '}
          <a
            href="https://broadcastdemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Broadcast
          </a>
          ,{' '}
          <a
            href="https://meshdemo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mesh
          </a>
          ,{' '}
          <a
            href="https://vanillademo.dunked.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Vanilla
          </a>
        </li>
        <li>
          <h3>WordPress Themes:</h3>
          Base, Sparks, Blox, Hoarder, Volumes, Launch, Scope
        </li>
        <li>
          <h3>WordPress Plugins:</h3>
          <a
            href="http://wordpress.org/plugins/zillaportfolio"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zilla Portfolio
          </a>
          , Zilla Dribbbler
        </li>
      </ol>
      <Footer message="← Back to home" />
    </Layout>
  )
}

export default ProjectsPage
